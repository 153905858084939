import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../utils/fontawesome"
import LogoPNG from "../utils/imges/logo-principal-2-rb.png"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const activeLink = {
  textDecoration: "none",
  opacity: "1",
}

const Layout = props => {
  const handleClick = () => {
    setToggleNav(!toggleNav)
  }
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <button className="nav-burger" onClick={handleClick}>
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </button>
          <nav id="swup" className={`site-head-left ${ !toggleNav ? 'hide' : ``}`}>
            {/* eslint-disable-next-line */}
            <ul className="nav" role="menu">
              {/* eslint-disable-next-line */}
              <li className="nav-home" role="menuitem">
                <Link to={`/`} activeStyle={activeLink} onClick={handleClick}>
                  Inicio
                </Link>
              </li>
              {/* eslint-disable-next-line */}
              <li className="nav-about" role="menuitem">
                <Link
                  to={`/about`}
                  activeStyle={activeLink}
                  onClick={handleClick}
                >
                  Nosotros
                </Link>
              </li>
              {/* eslint-disable-next-line */}
              <li className="nav-contactus" role="menuitem">
                <Link
                  to={`/contactus`}
                  activeStyle={activeLink}
                  onClick={handleClick}
                >
                  Contáctenos
                </Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
            <img src={LogoPNG} alt="Andrey Arquitecto" /> 
              <span className="hidden">Andrey Arquitecto</span>
            </Link>
          </div>
          <div className={`site-head-right ${!toggleNav ? `hide` : `` }`}>
            <div className="social-links">
              <a
                href="https://www.facebook.com/Andreyarquitecto"
                title="Facebook | Andreyarquitecto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/andreyarquitecto/"
                title="Instagram | andreyarquitecto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
              </a>
              <a
                href="https://wa.me/50685759745?text=Hola%2C%20estoy%20interesado%20en%20conocer%20m%C3%A1s%20de%20su%20servicio%20como%C2%A0arquitecto"
                title="WhatsApp | Andrey Arquitecto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </a>              
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
      </footer>
    </div>
  )
}

export default Layout
